import React from 'react';
import Header from './header/header';
import MainContent from './main/main-content';
import ProblemContent from './problem/problem-content';
import SolutionContent from './soultion/solution-content';
import WorkContent from './work/work-content';
import PriceTierContent from './price-tier/price-tier-content';
import FAQContent from './faq/faq-content';
import FooterContent from './footer/footer-content';
import FooterPageContent from './footer/footer-page-content';

const LandingPage = () => {
    return (
        <>
            <Header />
            <MainContent />
            <ProblemContent />
            <SolutionContent />
            <WorkContent />
            <PriceTierContent />
            <FAQContent />
            <FooterContent />
            <FooterPageContent />
        </>
    );
};

export default LandingPage;
