import React, { useState } from "react";
import { Box, Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useNotification } from "../../contexts/notification-context";
import { useAuth } from "../../contexts/auth-context";
import { auth } from "../../config/firebase-config";
import UploadModal from "../../upload/upload-modal";
import { UploadDetailsFormI } from "./interfaces";

export const UploadDetailsForm: React.FC<UploadDetailsFormI> = ({
  file,
  setFile,
  isMobile,
}) => {
  const [uploadName, setUploadName] = useState("");
  const [price, setPrice] = useState("");
  const [uploadNameTouched, setUploadNameTouched] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionTouched, setDescriptionTouched] = useState(false);
  const [priceTouched, setPriceTouched] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { isLoggedIn, fetchWallet, subscription } = useAuth();
  const { showNotification } = useNotification();

  const isFieldError = (fieldValue: string) => fieldValue === "";

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleUploadClick = async () => {
    if (!isLoggedIn || !auth.currentUser?.uid) {
      showNotification("Please login to continue!");
    } else {
      const { account, error } = await fetchWallet(auth.currentUser.uid);
      if (error || !account) {
        showNotification(
          "You need to setup your stripe account in order to upload content. Please go to the Wallets tab."
        );
      } else if (!subscription && file && file.size > 50 * 1024 * 1024) {
        showNotification("The file size must be less than or equal to 50MB");
      } else if (description.length > 500) {
        showNotification(
          "The description must be less than or equal to 500 characters"
        );
      } else if (parseFloat(price) < 1.0) {
        showNotification("The price must be at least $1.00");
      } else {
        setShowUploadModal(true);
      }
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <Box
        sx={{
          height: { xs: "auto", sm: "400px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={uploadName}
          error={uploadNameTouched && isFieldError(uploadName)}
          helperText="Give a short name to your product"
          onChange={(e) => setUploadName(e.target.value)}
          onBlur={() => setUploadNameTouched(true)}
          margin="normal"
          sx={{ mt: 0 }}
          required
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={description}
          error={descriptionTouched && isFieldError(description)}
          helperText="Briefly describe your content (max 500 characters). Highlight uniqueness and reasons for viewers to purchase."
          onChange={(e) => setDescription(e.target.value)}
          onBlur={() => setDescriptionTouched(true)}
          margin="normal"
          required
        />
        <TextField
          label="Price"
          variant="outlined"
          fullWidth
          value={price}
          error={priceTouched && isFieldError(price)}
          helperText="Enter the price for your product"
          onChange={(e) => setPrice(e.target.value)}
          onBlur={() => setPriceTouched(true)}
          margin="normal"
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: isMobile ? "row" : "row",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleUploadClick}
            disabled={
              isFieldError(uploadName) ||
              isFieldError(description) ||
              isFieldError(price)
            }
            sx={{ width: isMobile ? "50%" : "auto" }}
          >
            Generate Link
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleRemoveFile}
            disabled={!file}
            sx={{ width: isMobile ? "50%" : "auto" }}
          >
            Remove Media
          </Button>
        </Box>
      </Box>
      <UploadModal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        files={file ? [file] : []}
        price={price}
        uploadName={uploadName}
      />
    </Grid>
  );
};

export default UploadDetailsForm;
