import COLORS from "../../theme/colors";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
    gap: "0.5rem",
  },
  sm: {
    width: 150,
    height: 150,
  },
  imgContainer: {
    position: "relative",
    width: 300,
    height: 300,
  },
  title: {
    color: COLORS.arsenic,
    textAlign: "center",
    fontSize: {
      xs: "1.5rem",
      sm:"2.5rem"
    },
  },
  paragraph: {
    color: COLORS.dimGray,
    maxWidth: 300,
    marginBottom: 2,
    textAlign: "center",
  },
};

export default styles;
