import React from 'react';
import { Box, Typography, Container, Grid, IconButton, Link, useMediaQuery, useTheme } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TersmOfServiceDoc from '../../assets/Pezmix terms of service.pdf';
import PrivacyPolicyDoc from '../../assets/Pezmix Privacy Policy.pdf';
import { Link as ScrollLink } from 'react-scroll';

const FooterPageContent = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="xl">
      <Box sx={{ flexGrow: 1, py: 4 }}>
        <Grid container direction={isSmallScreen ? 'column' : 'row'} justifyContent="space-between" alignItems="flex-start" spacing={isSmallScreen ? 4 : 0}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" component="div">
              pezmix
            </Typography>
            <Typography variant="body2" sx={{ mb: 4 }}>
              pezmix. All rights reserved. © 2024
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container direction={isSmallScreen ? 'column' : 'row'} justifyContent={isSmallScreen ? 'flex-start' : 'flex-end'} alignItems="flex-start" spacing={2}>
            <Grid item xs={12} sm={4} md={2}>
                <Typography variant="subtitle1"><b>About</b></Typography>
                <ScrollLink to="features" smooth={true} duration={500}>
                  <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>Features</Typography>
                </ScrollLink>
                <ScrollLink to="pricing" smooth={true} duration={500}>
                  <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>Pricing</Typography>
                </ScrollLink>
                <ScrollLink to="works" smooth={true} duration={500}>
                  <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>Why Pezmix</Typography>
                </ScrollLink>
                <ScrollLink to="faq" smooth={true} duration={500}>
                  <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>FAQ</Typography>
                </ScrollLink>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Typography variant="subtitle1"><b>Legal</b></Typography>
                <Link href={PrivacyPolicyDoc} target="_blank" style={{ textDecoration: 'none' }}>
                  <Typography variant="subtitle2" color="textPrimary">Privacy Policy</Typography>
                </Link>
                <Link href={TersmOfServiceDoc} target="_blank" style={{ textDecoration: 'none' }}>
                  <Typography variant="subtitle2" color="textPrimary">Terms of Use</Typography>
                </Link>
                <Typography variant="subtitle2">License</Typography>
                <Typography variant="subtitle2">Cookies</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Box sx={{ display: 'flex', justifyContent: isSmallScreen ? 'flex-start' : 'flex-end' }}>
                  <IconButton href="https://twitter.com">
                    <XIcon />
                  </IconButton>
                  <IconButton href="https://facebook.com">
                    <FacebookIcon />
                  </IconButton>
                  <IconButton href="https://www.instagram.com/joinpezmix/">
                    <InstagramIcon />
                  </IconButton>
                  <IconButton href="https://linkedin.com">
                    <LinkedInIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default FooterPageContent;