import React from 'react';
import { Box, Typography, Button, Container, SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BrowerExample from '../../assets/brower-example.svg';
import { ReactComponent as ArrowLogo } from '../../assets/button-arrow.svg';
import UsersIcon from '../../assets/users.svg';
import { useNavigate } from 'react-router-dom';
import { getTypographyStyle } from '../../theme/theme';

const MainContent = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Container maxWidth="xl">
            <Box sx={{ mt: 10, mb: 4, textAlign: 'center' }}>
                <Container maxWidth="xs">
                    <img src={UsersIcon} alt="Users Icon" style={{ width: '100%', height: 'auto', paddingBottom: '20px' }} />
                </Container>
                <Typography variant="h1" component="div">
                    <span style={{ fontStyle: 'italic', color: theme.palette.primary.main }}>Sell more.</span> <span style={{ color: theme.palette.secondary.main }}>Stress less.</span>
                </Typography>
                <Typography variant="h6" sx={{ ...getTypographyStyle(400), color: theme.palette.secondary.main }}>
                    Turn your content into cash: effortless sales, genuine profits.
                </Typography>
                <Typography variant="h6" sx={{ ...getTypographyStyle(400), color: theme.palette.secondary.main, mt: 1 }}>
                    No subscriptions, just success.
                </Typography>
                <Button variant="contained"
                    endIcon={
                        <SvgIcon
                            component={ArrowLogo}
                            sx={{
                                color: theme.palette.common.white,
                            }}
                            inheritViewBox
                        />
                    }
                    sx={{ mt: 2, borderRadius: '10px', mb: 4 }}
                    onClick={() => navigate('/login')}
                >
                    Start Selling for Free
                </Button>
                <img src={BrowerExample} alt="Browser Example" style={{ width: '100%', height: 'auto' }} />
            </Box>
        </Container>
    );
};

export default MainContent;

