import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../utils/axios-instance";
import { useNotification } from "../contexts/notification-context";
import SellCard from "../components/sellCard";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import EmailRegistertionStep from "../components/emailRegisterStep";
import theme from "../theme/theme";
import { styles } from "./styles";
import { Rating } from "../components/rating-component/rating-component-interface";
import { RatingComponent } from "../components/rating-component/rating-component";

const PaymentsPage: React.FC = () => {
  const { contentId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { showNotification } = useNotification();
  const [file, setFile] = useState({});
  const [action, setAction] = useState<string>("default");
  const [ratings, setRatings] = useState<Rating>({
    likes: 0,
    dislikes: 0,
    ratingAction: null,
    percentage: { value: 0, color: undefined },
    disabledMode: true,
    handleRating: () => null,
  });
  useEffect(() => {
    const fetchPaymentInfo = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/payment_info/${contentId}`
        );
        const data = response.data;
        if (response.status === 200) {
          const { likes, dislikes } = {
            likes: data.likes,
            dislikes: data.dislikes,
          };
          const { calculatedPercentage, percentageColor } = {
            calculatedPercentage: 0,
            percentageColor: "",
          };
          if (response.status === 200) {
            setFile(data);
            setIsLoading(false);
            setRatings({
              ...ratings,
              likes: likes ?? 0,
              dislikes: dislikes ?? 0,
              percentage: {
                value: data.percentage,
                color: data.percentageColor,
              },
            });
          } else {
            throw new Error("Failed to fetch payment information");
          }
        }
      } catch (error) {
        showNotification(`Error: ${error}`);
      }
    };
    fetchPaymentInfo();
  }, [contentId]);

  if (isLoading)
    return (
      <Backdrop
        open={isLoading || isProcessing}
        sx={{ zIndex: theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  if (action == "EmailRegister")
    return (
      <EmailRegistertionStep setIsProcessing={setIsProcessing} file={file} />
    );
  return (
    <Container maxWidth="md" sx={styles.container}>
      <SellCard file={file} setAction={setAction} ratings={ratings} />
    </Container>
  );
};

export default PaymentsPage;
