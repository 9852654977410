import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Avatar, IconButton, TextField, Button, useMediaQuery, Backdrop, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from '../contexts/auth-context';
import { auth, storage } from '../config/firebase-config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import theme from '../theme/theme';
import { updateProfile } from 'firebase/auth';
import axiosInstance from '../utils/axios-instance';
import { useNotification } from '../contexts/notification-context';

const SettingsPage = () => {
    const { fetchWallet, customerId } = useAuth();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [displayName, setDisplayName] = useState(auth.currentUser?.displayName || '');
    const [profileImage, setProfileImage] = useState(auth.currentUser?.photoURL || '');
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const { showNotification } = useNotification();

    useEffect(() => {
        const fetchData = async () => {
            if (auth.currentUser) {
                setDisplayName(auth.currentUser.displayName || '');
                setProfileImage(auth.currentUser.photoURL || '');
                await fetchWallet(auth.currentUser.uid);
                setInitialLoading(false);
            }
        };
        fetchData();
    }, [auth.currentUser]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setImageFile(event.target.files[0]);
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfileImage(e.target?.result as string);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleSaveChanges = async () => {
        if (!auth.currentUser) return;

        setLoading(true);

        if (imageFile) {
            const storageRef = ref(storage, `${auth.currentUser.uid}/profile/image`);
            await uploadBytes(storageRef, imageFile);
            const photoURL = await getDownloadURL(storageRef);
            await updateProfile(auth.currentUser, { photoURL });
            setProfileImage(photoURL);
        }

        if (displayName !== auth.currentUser.displayName) {
            await updateProfile(auth.currentUser, { displayName });
        }

        setLoading(false);
        window.location.reload();
    };

    const handleManageSubscription = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('/api/manage_subscription', { customerId });
    
            if (response.status !== 200) {
                throw new Error('Failed to create billing portal session');
            }
    
            window.location.href = response.data.url;
        } catch (error) {
            showNotification(`Error managing subscription ${error}`);
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <Container
            maxWidth="xl"
            sx={{
                background: 'transparent',
                display: 'flex',
                flexDirection: 'column',
                alignItems: isMobile ? 'center' : 'flex-start',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    mb: 3,
                }}
            >
                <Typography
                    variant="h4"
                    sx={{ textAlign: isMobile ? 'center' : 'left' }}
                >
                    Settings
                </Typography>
                {customerId && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleManageSubscription}
                        disabled={!customerId}
                    >
                        Manage Subscription
                    </Button>
                )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <Avatar src={profileImage} sx={{ width: 80, height: 80 }} />
                <IconButton component="label">
                    <EditIcon />
                    <input type="file" hidden onChange={handleImageChange} />
                </IconButton>
            </Box>
            <TextField
                label="Username"
                variant="outlined"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                sx={{ mb: 3, width: '100%', maxWidth: '400px' }}
            />
            <Button variant="contained" color="primary" onClick={handleSaveChanges}>
                Save Changes
            </Button>
            <Backdrop open={loading || initialLoading} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
};

export default SettingsPage;
