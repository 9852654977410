import React, { useEffect, useState } from 'react';
import { Box, Card, CardMedia, CardContent, CircularProgress, Backdrop, Typography, Container, useMediaQuery, useTheme, Grid } from '@mui/material';
import { ref, getDownloadURL, list, getMetadata, deleteObject } from 'firebase/storage';
import { auth, storage } from '../config/firebase-config';
import { FileData } from 'pezmix-common';
import ContentModal from './content-modal';
import { useAuth } from '../contexts/auth-context';
import { deleteDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import DescriptionIcon from '@mui/icons-material/Description';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getTypographyStyle } from '../theme/theme';
import { useNotification } from '../contexts/notification-context';
import formatString from '../utils/format-string';
import { Feedback } from '../components/feedback';

const ContentPage = () => {
  const [files, setFiles] = useState<FileData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentFile, setCurrentFile] = useState<FileData | null>(null);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisibleFile, setLastVisibleFile] = useState<string | null>(null);
  const { isAuthReady } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showNotification } = useNotification();

  const handleOpenModal = (file: FileData) => {
    setCurrentFile(file);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const fetchFiles = async () => {
    if (!isAuthReady) {
      return;
    }

    setLoading(true);

    const userUid = auth.currentUser?.uid;
    const userName = auth.currentUser?.displayName;

    if (!userUid || !userName) {
      showNotification(`No user UID found`);

      setLoading(false);
      return;
    }

    const folderRef = ref(storage, `${userUid}/`);
    const fileList = await list(folderRef, { maxResults: 25, pageToken: lastVisibleFile });

    if (!fileList.nextPageToken) {
      setHasMore(false);
    } else {
      setLastVisibleFile(fileList.nextPageToken);
    }

    const filePromises = fileList.items.map(async (itemRef): Promise<FileData> => {
      try {
        const downloadUrl = await getDownloadURL(itemRef);
        const fullPath = itemRef.fullPath;
        const metadata = await getMetadata(itemRef);
        const uniqueId = metadata.customMetadata?.uniqueId;
        const fileType = metadata.contentType?.split('/')[0];;

        if (!uniqueId) {
          showNotification(`No uniqueId found in metadata for ${itemRef.name}`);
          return { name: itemRef.name, authorUid: userUid, authorName: userName, url: downloadUrl, path: fullPath, fileType: fileType };
        }

        const docRef = doc(db, 'shared_content', uniqueId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          showNotification(`No Document not found in Firestore for uniqueId ${uniqueId}`);
          return { name: itemRef.name, authorUid: userUid, authorName: userName, url: downloadUrl, path: fullPath, fileType: fileType };
        }

        return {
          name: docSnap.data().fileName,
          authorUid: userUid,
          authorName: userName,
          url: downloadUrl,
          path: fullPath,
          link: docSnap.data().link,
          createdAt: docSnap.data().createdAt.toDate(),
          price: docSnap.data().price,
          accessType: docSnap.data().accessType,
          fileType: fileType,
          earnings: docSnap.data().earnings
        };
      } catch (error) {
        showNotification(`Error fetching file data ${error}`);

        return { name: itemRef.name, authorUid: userUid, authorName: userName, url: '', path: '', fileType: 'unknown' };
      }
    });

    const newFiles = await Promise.all(filePromises);
    setFiles((prevFiles) => [...prevFiles, ...newFiles.filter(file => file !== null)]);
    setLoading(false);
  };

  const deleteFile = async (file: FileData) => {
    try {
      const fileRef = ref(storage, file.path);
      const metadata = await getMetadata(fileRef);
      const uniqueId = metadata.customMetadata?.uniqueId;

      await deleteObject(fileRef);
      if (uniqueId) {
        const docRef = doc(db, 'shared_content', uniqueId);
        await deleteDoc(docRef);
      }
      setFiles((prevFiles) => prevFiles.filter((f) => f.path !== file.path));
      showNotification(`File ${file.name} deleted successfully`);
    } catch (error) {
      showNotification(`Error deleting file: ${(error as Error).message}`);
    }
  };

  const getFileIcon = (fileType: string) => {
    switch (fileType) {
      case 'image':
        return null;
      case 'video':
        return <VideoLibraryIcon sx={{ width: 80, height: 80, borderRadius: '8px' }} />;
      case 'audio':
        return <MusicNoteIcon sx={{ width: 80, height: 80, borderRadius: '8px' }} />;
      default:
        return <DescriptionIcon sx={{ width: 80, height: 80, borderRadius: '8px' }} />;
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [isAuthReady]);

  return (
    <Container maxWidth="xl" sx={{ background: 'transparent', display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'center' : 'flex-start' }}>
      <Typography variant="h4" sx={{ mb: 3, textAlign: isMobile ? 'center' : 'left' }}>
        Links
      </Typography>
      {loading && files.length === 0 ? (
        <Backdrop open={loading} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box sx={{ height: 600, overflow: 'auto', width: '100%', paddingRight: '1rem' }} id="scrollableDiv">
          <InfiniteScroll
            dataLength={files.length}
            next={fetchFiles}
            hasMore={hasMore}
            loader={<CircularProgress color="inherit" />}
            endMessage={<Typography variant="body2" color="text.secondary" sx={getTypographyStyle(400)}>No more files to load</Typography>}
            scrollableTarget="scrollableDiv"
          >
            <Grid container spacing={2}>
              {files.map((file, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Card sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 1 }} onClick={() => handleOpenModal(file)}>
                    {file.fileType === 'image' ? (
                      <CardMedia
                        component="img"
                        sx={{ width: 80, height: 80, borderRadius: '8px' }}
                        image={file.url}
                        alt={file.name}
                      />
                    ) : file.fileType === 'video' ? (
                      <CardMedia
                        component="video"
                        sx={{ width: 80, height: 80, borderRadius: '8px' }}
                        image={file.url}
                      />
                    ) : (
                      getFileIcon(file.fileType || 'unknown')
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1, ml: 1 }}>
                      <CardContent sx={{ paddingBottom: '8px !important' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Box>
                            <Typography component="div" variant="subtitle1" sx={getTypographyStyle(500)}>
                              {file.name.length > 16 ? `${file.name.slice(0, 13)}...` : file.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" component="div" sx={getTypographyStyle(400)}>
                              Created: {file.createdAt ? new Date(file.createdAt).toLocaleDateString() : 'N/A'}
                            </Typography>
                          </Box>
                          <Box sx={{ textAlign: 'right' }}>
                            <Typography variant="subtitle1" component="div" sx={getTypographyStyle(400)}>
                              {formatString(file.earnings)}
                            </Typography>
                            <Typography component="div" color="text.secondary" variant="body2" sx={getTypographyStyle(400)}>
                              Earnings
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        </Box>
      )}
      {currentFile && (
        <ContentModal
          open={openModal}
          onClose={handleCloseModal}
          onDelete={deleteFile}
          file={currentFile}
        />
      )}
    </Container>
  );
};

export default ContentPage;
