import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Container,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAuth } from "../contexts/auth-context";
import { useLocation, useNavigate } from "react-router-dom";
import { getTypographyStyle } from "../theme/theme";
import TersmOfServiceDoc from "../assets/Pezmix terms of service.pdf";
import axiosInstance from "../utils/axios-instance";
import { accountTypeT } from "./interfaces";

const RegistrationPage = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [accountType, setAccountType] = useState<accountTypeT>("fan");
  const location = useLocation();
  const redirectUrl =
    new URLSearchParams(location.search).get("redirect") || "/app/upload";

  const { register } = useAuth();
  const navigate = useNavigate();

  const isFormComplete =
    username && email && password && confirmPassword && acceptTerms && accountType;
  const isFieldError = (fieldValue: string) => fieldValue === "";

  const handleRegister = async () => {
    await axiosInstance.post("/api/v1/registerUserDetails",{
      username:username,
      email:email,
      accountType:accountType
    })
    register(username, email, password, redirectUrl,accountType);
    
  };

  const handleOldUserClick = () => {
    const searchParams = new URLSearchParams(location.search);
    navigate(`/login?${searchParams.toString()}`);
  };

  const doPasswordsMatch = (password: string, confirmPassword: string) => {
    return password === confirmPassword;
  };
  const handleAccountTypeValue = (event: SelectChangeEvent) => {
    setAccountType(event.target.value as accountTypeT);
  };

  const TermsAndConditionsLabel = () => {
    return (
      <Typography
        variant="body2"
        color="textPrimary"
        sx={getTypographyStyle(400)}
      >
        Accept&nbsp;
        <a
          href={TersmOfServiceDoc}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          terms and conditions
        </a>
      </Typography>
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <Container
        maxWidth="xs"
        sx={{
          background: "white",
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          position: "relative",
        }}
      >
        <IconButton
          onClick={() => navigate("/")}
          sx={{ position: "absolute", top: 8, left: 8 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h5"
          textAlign="center"
          marginBottom={2}
          sx={getTypographyStyle(500)}
        >
          Create an account
        </Typography>
        <TextField
          fullWidth
          label="Username"
          error={usernameTouched && isFieldError(username)}
          helperText={
            usernameTouched && isFieldError(username)
              ? "Username is required"
              : ""
          }
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onBlur={() => setUsernameTouched(true)}
          margin="dense"
          InputProps={{
            style: getTypographyStyle(400),
          }}
          InputLabelProps={{
            style: getTypographyStyle(400),
          }}
        />
        <TextField
          fullWidth
          label="Email"
          error={emailTouched && isFieldError(email)}
          helperText={
            emailTouched && isFieldError(email) ? "Email is required" : ""
          }
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => setEmailTouched(true)}
          margin="dense"
          InputProps={{
            style: getTypographyStyle(400),
          }}
          InputLabelProps={{
            style: getTypographyStyle(400),
          }}
        />
        <TextField
          fullWidth
          label="Password"
          error={passwordTouched && isFieldError(password)}
          helperText={
            passwordTouched && isFieldError(password)
              ? "Password is required"
              : ""
          }
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={() => setPasswordTouched(true)}
          margin="dense"
          InputProps={{
            style: getTypographyStyle(400),
          }}
          InputLabelProps={{
            style: getTypographyStyle(400),
          }}
        />
        <TextField
          fullWidth
          label="Confirm Password"
          error={
            confirmPasswordTouched &&
            (isFieldError(confirmPassword) ||
              !doPasswordsMatch(password, confirmPassword))
          }
          helperText={
            confirmPasswordTouched &&
            !doPasswordsMatch(password, confirmPassword)
              ? "Passwords do not match"
              : confirmPasswordTouched && isFieldError(confirmPassword)
              ? "Password confirm is required"
              : ""
          }
          variant="outlined"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onBlur={() => setConfirmPasswordTouched(true)}
          margin="dense"
          InputProps={{
            style: getTypographyStyle(400),
          }}
          InputLabelProps={{
            style: getTypographyStyle(400),
          }}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel sx={{}} id="demo-simple-select-label">Creator or Fan</InputLabel>
          <Select
            value={accountType}
            label="Creator or Fan"
            onChange={handleAccountTypeValue}
          >
            <MenuItem value="Creator">Creator</MenuItem>
            <MenuItem value="Fan">Fan</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptTerms}
              onChange={(e) => setAcceptTerms(e.target.checked)}
            />
          }
          label={<TermsAndConditionsLabel />}
        />
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
          disabled={!isFormComplete}
          onClick={handleRegister}
        >
          Register
        </Button>
        <Box display="flex" justifyContent="center">
          <Typography
            variant="body2"
            color="textSecondary"
            sx={getTypographyStyle(400)}
          >
            Already have an account?&nbsp;
          </Typography>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              ...getTypographyStyle(400),
            }}
            onClick={handleOldUserClick}
          >
            Sign In
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default RegistrationPage;
