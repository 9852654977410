import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box, Slide } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import useScrollDirection from '../../utils/scroll-direction'
import { getTypographyStyle } from '../../theme/theme';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: 'none',
}));

const Header = () => {
  const navigate = useNavigate();
  const scrollDirection = useScrollDirection();

  return (
    <Slide appear={false} direction="down" in={scrollDirection === 'up'}>
      <StyledAppBar position="fixed">
        <Container maxWidth="xl">
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="div" sx={{ flex: 1 }}>
              pezmix
            </Typography>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', flex: 1 }}>
              <ScrollLink to="features" smooth={true} duration={500}>
                <Button sx={getTypographyStyle(500)}>Features</Button>
              </ScrollLink>
              <ScrollLink to="pricing" smooth={true} duration={500}>
                <Button sx={getTypographyStyle(500)}>Pricing</Button>
              </ScrollLink>
              <ScrollLink to="faq" smooth={true} duration={500}>
                <Button sx={getTypographyStyle(500)}>FAQ</Button>
              </ScrollLink>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
              <Button 
                variant="outlined" 
                sx={{ 
                  marginRight: { xs: 0.5, md: 1.5 }, 
                  padding: { xs: '4px 8px', md: '6px 16px' }, 
                  fontSize: { xs: '0.8rem', md: '1rem' }, 
                  minWidth: { xs: '90px', md: 'auto' }
                }} 
                onClick={() => navigate('/login')}
              >
                Login
              </Button>
              <Button 
                variant="contained" 
                sx={{ 
                  padding: { xs: '4px 8px', md: '6px 16px' }, 
                  fontSize: { xs: '0.8rem', md: '1rem' }, 
                  minWidth: { xs: '110px', md: 'auto' }
                }} 
                onClick={() => navigate('/register')}
              >
                Start Selling
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </StyledAppBar>
    </Slide>
  );
};

export default Header;
