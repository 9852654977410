export const styles = {
  mainCard: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
  },
  LinkImgae: {
    width: {
      xs: "100%",
      sm: "70%",
    },
    height: "100%",
  },

  linkAudio: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70%",
    height: 300,
    bgcolor: "white",
  },
  linkContent: {
    gap: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "1rem",
  },
  linkDetails: { display: "flex", flexDirection: "column" },
  priceContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
  },
  discount: { textDecoration: "line-through" },
  btnsContainer: {
    width: "100%;",
    height: "100%",
    flexDirection: "row",
    display: "flex",
    alignItems: "flex-end",
    gap: "1rem",
  },
  customFont: { fontSize: 100 },
};
