import React from 'react';
import { Typography, Container } from '@mui/material';
import HowItWorks from '../../assets/how-it-works.svg';

const WorkContent = () => {
    return (
        <Container id="works" maxWidth="xl">
            <Typography variant="subtitle1" sx={{ mt: 6, mb: 4, textAlign: 'center' }}>
                HOW IT WORKS
            </Typography>
            <Typography variant="h3" sx={{ textAlign: 'center' }}>
                Just 4 steps to <i>earn your first dollar</i>
            </Typography>
            <img src={HowItWorks} alt="How it works list" style={{ width: '100%', height: 'auto' }} />
        </Container>
    );
};

export default WorkContent;
