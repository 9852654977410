import React from 'react';
import { Box, Typography, Container, Card, CardContent, Grid, Button, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth-context';
import CheckIcon from '../../assets/checkmark.svg';

const PriceTierContent = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();

    const handleSubscription = (priceId: string | null) => {
        if (priceId) {
            const redirectUrl = `/create-sub-checkout-session?priceId=${priceId}`;
            if (!isLoggedIn) {
                navigate(`/login?redirect=${encodeURIComponent(redirectUrl)}`);
            } else {
                navigate(redirectUrl);
            }
        } else {
            navigate('/login');
        }
    };

    const cards = [
        {
            title: "Free",
            description: "No monthly fees or subscription cost (no credit card required)",
            price: "FREE",
            features: [
                "Unlimited uploads",
                "Unlimited sales",
                "Basic analytics",
                "20% commission from each sale goes to Pezmix",
                "Payments in cash app, apple pay, and debit/credit cards"
            ],
            bgColor: 'background.paper',
            priceId: null
        },
        {
            title: "Premium",
            description: "No monthly fees or subscription cost (no credit card required)",
            price: "$50/mo",
            features: [
                "All Free Plan features",
                "0% commission on each sale, keep 100% of your profits!",
                "Payments in cryptocurrency",
                "Advanced analytics",
                "Early access to new features"
            ],
            bgColor: theme.palette.primary.main,
            priceId: process.env.REACT_APP_PRICE_ID_50!,
        },
        {
            title: "Annual",
            description: "save 20% compared to the monthly Premium Plan.",
            price: "$500/yr",
            features: [
                "All Free Plan features",
                "0% commission on each sale, keep 100% of your profits!",
                "Payments in cryptocurrency",
                "Advanced analytics",
                "Early access to new features"
            ],
            bgColor: 'background.paper',
            priceId: process.env.REACT_APP_PRICE_ID_500!
        }
    ];

    return (
        <Container id="pricing" maxWidth="xl" sx={{ mb: 6 }}>
            <Typography variant="subtitle1" sx={{ mt: 6, mb: 4, textAlign: 'center' }}>
                PRICING
            </Typography>
            <Typography variant="h3" sx={{ mb: 4, textAlign: 'center' }}>
                Simple, <i>transparent pricing</i>
            </Typography>
            <Grid container spacing={2}>
                {cards.map((card, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                                borderRadius: '24px',
                                borderColor: theme.palette.secondary.main,
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                bgcolor: card.bgColor,
                                color: card.bgColor === theme.palette.primary.main ? 'white' : 'inherit',
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" sx={{
                                    color: card.bgColor === theme.palette.primary.main ? 'white' : 'inherit'
                                }}>{card.title}</Typography>
                                <Typography>{card.description}</Typography>
                                <Typography variant="h3" sx={{ my: 2 }}>{card.price}</Typography>
                                <Divider sx={{ my: 1 }} />
                                {card.features.map((feature, idx) => (
                                    <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                        <Box component="img" src={CheckIcon} alt="check" sx={{ width: 20, height: 20, color: 'success.main' }} />
                                        <Typography sx={{ ml: 1 }}>{feature}</Typography>
                                    </Box>
                                ))}
                            </CardContent>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 2,
                                    mb: 2,
                                    width: 'auto',
                                    bgcolor: card.bgColor === theme.palette.primary.main ? 'white' : 'primary.main',
                                    color: card.bgColor === theme.palette.primary.main ? theme.palette.secondary.main : 'white',
                                    alignSelf: 'center',
                                    '&:hover': {
                                        bgcolor: card.bgColor === theme.palette.primary.main ? theme.palette.grey[200] : theme.palette.primary.dark,
                                    },
                                }}
                                onClick={() => handleSubscription(card.priceId)}
                            >
                                Get Started with {card.title}
                            </Button>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default PriceTierContent;
