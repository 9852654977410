import React from 'react';
import { Box, Typography, Button, SvgIcon, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Footer from '../../assets/footer.svg';
import { ReactComponent as ArrowLogo } from '../../assets/button-arrow.svg';
import { useNavigate } from 'react-router-dom';

const FooterContent = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const navigate = useNavigate();

    return (
        <Box sx={{
            backgroundImage: `url(${Footer})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: isSmallScreen ? 'auto' : 400,
            textAlign: 'center',
            width: '100%',
            padding: theme.spacing(3) 
        }}>
            <Container>
                <Typography variant="h1" sx={{
                    color: 'white',
                    fontSize: isSmallScreen ? theme.typography.h4.fontSize : isMediumScreen ? theme.typography.h3.fontSize : theme.typography.h1.fontSize,
                    textAlign: 'center',
                    marginBottom: 2
                }}>
                    Your next payout<br />
                    is just a <i>click away</i>
                </Typography>
                <Button
                    variant="contained"
                    endIcon={
                        <SvgIcon
                            component={ArrowLogo}
                            sx={{ color: theme.palette.secondary.main }}
                            inheritViewBox
                        />
                    }
                    sx={{
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        color: theme.palette.text.primary,
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                        px: isSmallScreen ? 1 : 5
                    }}
                    onClick={() => navigate('/login')}
                >
                    Start Selling for Free
                </Button>
            </Container>
        </Box>
    );
};

export default FooterContent;