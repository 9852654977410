import React from 'react';
import { Box, Typography, Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const FAQContent = () => {
    const faqs = [
        { question: "Can I really upload any type of digital content?", answer: "Yes, Pezmix allows you to upload and sell all types of digital content, from podcast episodes, courses, videos, and whatever else you can upload." },
        { question: "How quickly can I start selling my content?", answer: "You can start selling your content immediately after uploading it and setting your price." },
        { question: "What about the security of my content and transactions?", answer: "Pezmix ensures the security of your content and transactions through encryption and secure payment processing via Stripe." },
        { question: "What makes Pezmix different from other platforms?", answer: "Pezmix stands out by offering a simple, user-friendly interface, competitive pricing, and flexible options for creators to monetize their work." },
        { question: "What payment methods can I accept through Pezmix?", answer: "Pezmix supports a variety of payment methods through Stripe, including credit cards, debit cards, Apple Pay, Cash App, crypto and more." }
    ];

    return (
        <Container id="faq" maxWidth="sm">
            <Box sx={{ mt: 10, textAlign: 'center', mb: 6}}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    FAQ
                </Typography>
                <Typography variant="h3" marginBottom='2rem'>
                    Questions? <i>Answers.</i>
                </Typography>
                {faqs.map((faq, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<AddIcon />}
                            aria-controls={`panel${index}a-content`}
                            id={`panel${index}a-header`}
                            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <Typography sx={{ flexBasis: '90%', textAlign: 'left' }}>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Container>
    );
};

export default FAQContent;
