import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Creators from '../../assets/creators.svg';

const ProblemContent = () => {
    const theme = useTheme();

    return (
        <Container maxWidth="md">
            <img src={Creators} alt="Creators List" style={{ width: '100%', height: 'auto' }} />
            <Box sx={{ mt: 10, textAlign: 'center' }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    THE PROBLEM
                </Typography>
                <Typography variant="h5" sx={{ mt: 2, color: theme.palette.secondary.main }}>
                    <span style={{ color: theme.palette.primary.main }}>
                        Your audience is stuck in a tough spot 
                    </span>, with inflation, rising costs, and likely dealing with subscription fatigue. Another subscription or paid app is the last thing they’re looking to sign up for.
                </Typography>
                <Typography variant="h5" sx={{ mt: 5, color: theme.palette.secondary.main }}>
                    It’s a real headache making money from your passion{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        without losing a big chunk of your earnings.
                    </span>
                </Typography>
            </Box>
        </Container>
    );
};

export default ProblemContent;
