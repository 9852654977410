import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Joyride, { CallBackProps } from 'react-joyride';
import theme from '../theme/theme';

const WalkthroughFlow = () => {
  const [run, setRun] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const hasWalkthroughBeenShown = localStorage.getItem('walkthroughShown');
    if (!hasWalkthroughBeenShown) {
      setRun(true);
      localStorage.setItem('walkthroughShown', 'true');
    }
  }, []);

  const handleCallback = (data: CallBackProps) => {
    const {
      action,
      step: {
        data: { next, previous },
      },
      type,
    } = data;
    const isPreviousAction = action === 'prev';

    if (type === 'step:after') {
      if (next || previous) {
        navigate(isPreviousAction && previous ? previous : next);
      }
    }
  };

  const steps = [
    {
        content: 'Welcome to Pezmix! Let us show you how to make the most of our platform.',
        placement: 'center' as const,
        target: 'body',
        data: {
            next: '/app/upload',
        },
    },
    {
      target: '.nav-upload',
      content: 'Click here to upload your content.',
      data: {
        next: '/app/content',
      },
    },
    {
      target: '.nav-content',
      content: 'Check here to see your uploaded content.',
      data: {
        next: '/app/wallet',
        previous: '/app/upload',
      },
    },
    {
      target: '.nav-wallet',
      content: 'To upload, you need to create your wallet. You can manage or create a wallet here.',
      data: {
        next: '/app/settings',
        previous: '/app/wallet',
      },
    },
    {
      target: '.nav-settings',
      content: 'Adjust or view your account settings here.',
      data: {
        next: '/app/upload',
        previous: '/app/wallet',
      },
    },
    {
      target: 'body',
      content: 'Here you can upload your content and share it with others to begin earning cash!',
      placement: 'center' as const,
      data: {
        next: '/app/content',
        previous: '/app/settings',
      },
    },
    {
      target: 'body',
      content: 'Here you can view all of your uploaded content, earnings, and much more.',
      placement: 'center' as const,
      data: {
        previous: '/app/upload',
        next: '/app/wallet',
      },
    },
    {
      target: 'body',
      content: 'In order to start earning on Pezmix, you will need to set up your wallet with our partner Stripe. This will allow you to earn cash ready for immediate deposit into your account, booyah!',
      placement: 'center' as const,
      data: {
        previous: '/app/upload',
        next: '/app/wallet',
      },
    },
    {
      target: '.wallet-setup',
      content: 'When you are ready, you can use this button to set up or manage your Pezmix wallet!',
      data: {
        previous: '/app/wallet',
        next: '/app/settings',
      },
    },
    {
      target: 'body',
      content: 'Here are general settings on our platform. Please use these to adjust your Pezmix experience.',
      placement: 'center' as const,
      data: {
        previous: '/app/wallet',
      },
    },
  ];

  return (
    <Joyride
      steps={steps}
      run={run}
      continuous
      showProgress
      showSkipButton
      callback={handleCallback}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: theme.palette.primary.main,
        },
      }}
    />
  );
};

export default WalkthroughFlow;
