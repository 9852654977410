import React, { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../config/firebase-config';
import { useAuth } from '../contexts/auth-context';
import axiosInstance from '../utils/axios-instance';
import { useNotification } from '../contexts/notification-context';

const SubscriptionRedirect = () => {
  const { subscription } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const priceId = new URLSearchParams(location.search).get('priceId');
  const userId = auth.currentUser?.uid;
  const { showNotification } = useNotification();

  useEffect(() => {
    if (subscription) {
      navigate('/app/upload');
      return;
    }

    const createSession = async () => {
      if (!priceId || !userId) {
        navigate('/login');
        return;
      }

      try {
        const response = await axiosInstance.get(`/create-sub-checkout-session?priceId=${priceId}&userId=${userId}`);
        window.location.href = response.data.url;
      } catch (error) {
        showNotification(`Error creating checkout session ${error}`);

        navigate('/app/upload');
      }
    };

    createSession();
  }, [navigate, priceId, userId, subscription]);

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default SubscriptionRedirect;
