import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { Modal, ModalContent, StyledBackdrop } from "./styles";
import { PostReport } from "../../api/queries";

export const ReportButton: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [reportMessage, setReportMessage] = useState<string>("");

  const handleSendReport = async () => {
    PostReport(reportMessage);
    setOpen(false);
    setReportMessage("");
  };
  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Report
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: 400 }}>
          <h2 className="modal-title">Report Message</h2>
          <p className="modal-description">
            Please provide us about the problem with that product
          </p>
          <TextField
            value={reportMessage}
            label="Report Message"
            onChange={(e) => setReportMessage(e.target.value)}
            multiline
            rows={4}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendReport}
          >
            Send
          </Button>
        </ModalContent>
      </Modal>
    </>
  );
};
