import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  MusicNote as MusicNoteIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { CreateCheckoutSession, PostEmails } from "../../api/queries";
import { styles } from "./styles";

export const EmailRegistertionStep: React.FC<any> = ({
  file,
  setIsProcessing,
}: any) => {
  const { contentId } = useParams();
  const { fileType, price, fileUrl, fileName } = file;
  const [email, setEmail] = useState("");
  const handlePayment = async () => {
    setIsProcessing(true);
    try {
      await PostEmails(email);
      const response = await CreateCheckoutSession(contentId);
      if (response.status === 200) {
        window.location.href = response.data.url;
      } else {
        throw new Error("Failed to create checkout session");
      }
    } catch (error) {
      console.log(error); // need to set notification
    }
  };
  return (
    <Stack sx={styles.EmailRegisterContainer}>
      <Stack maxWidth="md" sx={styles.StackConatainer}>
        <Stack>
          {fileUrl ? (
            <Box
              component="img"
              src={fileUrl}
              alt={fileName}
              sx={styles.LinkImgae}
            />
          ) : (
            <Box sx={styles.linkAudio}>
              {fileType === "audio" ? (
                <MusicNoteIcon sx={styles.customFont} />
              ) : (
                <DescriptionIcon sx={styles.customFont} />
              )}
            </Box>
          )}
          <Stack sx={styles.MediaSection}>
            <Typography color="text.primary">1 Media</Typography>
            <Typography color="text.primary">{price}$</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Card sx={styles.EmailForm}>
        <Typography>Email</Typography>
        <TextField
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button variant="contained" onClick={handlePayment}>
          Continue
        </Button>
      </Card>
    </Stack>
  );
};

export default EmailRegistertionStep;
