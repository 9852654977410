import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  CircularProgress,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { auth, nanoid, storage } from "../config/firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FileData } from "pezmix-common";
import { generateContentLink } from "../utils/generate-content-link";
import { useNotification } from "../contexts/notification-context";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  maxHeight: "90vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
  files: File[];
  price: string;
  uploadName: string;
}

const UploadModal: React.FC<UploadModalProps> = ({
  open,
  onClose,
  files,
  price,
  uploadName,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean | null>(null);
  const [uploadError, setUploadError] = useState("");
  const [downloadURL, setDownloadURL] = useState("");
  const { showNotification } = useNotification();

  const onFileUpload = () => {
    setUploading(true);
    setUploadSuccess(null);
    setUploadError("");

    files.forEach((file) => {
      const uniqueId = nanoid();

      const metadata = {
        customMetadata: {
          uniqueId: uniqueId,
        },
      };

      const storageRef = ref(storage, `${auth.currentUser?.uid}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          showNotification(`Upload failed ${error}`);
          setUploadError("Upload failed: " + error.message);
          setUploading(false);
          setUploadSuccess(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const fullPath = uploadTask.snapshot.ref.fullPath;

          const fileData: FileData = {
            name: uploadName,
            authorUid: auth.currentUser?.uid || "",
            authorName: auth.currentUser?.displayName || "",
            url: downloadURL,
            path: fullPath,
          };

          const priceValue = parseFloat(price);
          const access = priceValue === 0 ? "public" : "private";
          const finalPrice = access === "public" ? "0" : price;

          const contentLink = await generateContentLink(
            fileData,
            access,
            finalPrice,
            uniqueId
          );

          setDownloadURL(contentLink);
          setUploading(false);
          setUploadSuccess(true);
        }
      );
    });
  };

  useEffect(() => {
    if (open && files.length > 0) {
      onFileUpload();
    }
  }, [open]);

  const handleCopy = () => {
    navigator.clipboard.writeText(downloadURL);
    // showNotification('Link copied to clipboard!');
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="upload-modal-title"
      aria-describedby="upload-modal-description"
    >
      <Box sx={modalStyle}>
        {uploading && (
          <>
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Uploading file...
            </Typography>
          </>
        )}
        {!uploading && uploadSuccess === true && (
          <>
            <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Uploaded file!
            </Typography>
            <TextField
              fullWidth
              label="Download URL"
              value={downloadURL}
              variant="outlined"
              sx={{ mt: 2 }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopy}>
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        {!uploading && uploadSuccess === false && (
          <>
            <ErrorIcon color="error" sx={{ fontSize: 60 }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              File upload failed!
            </Typography>
            <Typography sx={{ mt: 1 }}>{uploadError}</Typography>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default UploadModal;
