import React from "react";
import "./App.css";
import LandingPage from "../landing/landing-page";
import LoginPage from "../login/login-page";
import RegistrationPage from "../registration/registration-page";
import { Route, Routes, Navigate } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import theme from "../theme/theme";
import { useAuth } from "../contexts/auth-context";
import NavigationMenu from "../navigation/navigation-menu";
import UploadPage from "../upload/upload-page";
import ContentPage from "../content/content-page";
import AccountPage from "../account/account-page";
import SettingsPage from "../settings/settings-page";
import PaymentsPage from "../payments/payments-page";
import ContentView from "../content/content-view";
import SubscriptionRedirect from "../subscription/subscription-redirect";
import WalkthroughFlow from "../walkthrough/walkthrough-flow";
import ErrorPage from "../components/ErrorPage";

function PezMixApp() {
  const { isLoggedIn, isAuthReady } = useAuth();

  if (!isAuthReady) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!isAuthReady}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/app/upload" /> : <LoginPage />}
        />
        <Route
          path="/register"
          element={
            isLoggedIn ? <Navigate to="/app/upload" /> : <RegistrationPage />
          }
        />
        <Route path="/content/:contentId" element={<PaymentsPage />} />
        <Route path="/content/view" element={<ContentView />} />
        <Route
          path="/create-sub-checkout-session"
          element={<SubscriptionRedirect />}
        />
        <Route
          path="/app/*"
          element={isLoggedIn ? <AppRoutes /> : <Navigate to="/login" />}
        />
      </Routes>
    </ThemeProvider>
  );
}

const AppRoutes = () => (
  <>
    <WalkthroughFlow />
    <Routes>
      <Route path="/" element={<NavigationMenu />}>
        <Route path="upload" element={<UploadPage />} />
        <Route path="content" element={<ContentPage />} />
        <Route path="wallet" element={<AccountPage />} />
        <Route path="settings" element={<SettingsPage />} />
      </Route>
      <Route
        path="*"
        element={
          <ErrorPage
            titleFontSize="h2"
            title={"Page not Found"}
            paragraph={"The page you are looking for is not found"}
          />
        }
      />
    </Routes>
  </>
);

export default PezMixApp;
