import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Container,
} from "@mui/material";
import { Description as DescriptionIcon } from "@mui/icons-material";
import theme from "../theme/theme";
import { useNotification } from "../contexts/notification-context";
import axiosInstance from "../utils/axios-instance";

import { Rating } from "../components/rating-component/rating-component-interface";
import { RatingComponent } from "../components/rating-component/rating-component";

const ContentView = () => {
  const [ratings, setRatings] = useState<Rating>({
    likes: 0,
    dislikes: 0,
    ratingAction: null,
    percentage: { value: 0, color: undefined },
    disabledMode: false,
    handleRating: (action) => handleRating(action),
  });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const accessToken = params.get("access_token");
  const [fileUrl, setFileUrl] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { showNotification } = useNotification();

  const handleRating = async (action: "like" | "dislike" | null) => {
    setIsLoading(true);

    try {
      const response = await axiosInstance.put(
        `/api/content/rating/${accessToken}`,
        {
          action: action,
        }
      );
      setIsLoading(false);

      if (response.status === 200) {
        const { likes, dislikes } = {
          likes: response.data.likes,
          dislikes: response.data.dislikes,
        };
        console.log(likes, dislikes);
        let data = response.data;
        setRatings({
          ...ratings,
          likes: likes ?? 0,
          dislikes: dislikes ?? 0,
          percentage: { value: data.percentage, color: data.percentageColor },
          ratingAction: data.latestRatingAction,
        });
      } else {
        showNotification(`Error: Failed to add rating`);
      }
    } catch (error) {
      showNotification(`Error: ${error}`);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const fetchContentUrl = async () => {
      try {
        const response = await axiosInstance.get(`/api/content/${accessToken}`);
        const data = response.data;
        const { likes, dislikes } = {
          likes: data.likes,
          dislikes: data.dislikes,
        };
        if (response.status === 200) {
          setFileUrl(data.fileUrl);
          setAuthorName(data.authorName);
          setFileName(data.fileName);
          setFileType(data.fileType);
          setIsLoading(false);
          setRatings({
            ...ratings,
            likes: likes ?? 0,
            dislikes: dislikes ?? 0,
            percentage: { value: data.percentage, color: data.percentageColor },
            ratingAction: data.latestRatingAction,
          });
        } else {
          throw new Error("Failed to fetch content URL");
        }
      } catch (error) {
        showNotification(`Error: ${error}`);
        setIsLoading(false);
      }
    };

    fetchContentUrl();

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [accessToken]);

  useEffect(() => {
    if (fileUrl) {
      handleDownloadClick();
    }
  }, [fileUrl]);

  const handleDownloadClick = () => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      const blob = xhr.response;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    };
    xhr.open("GET", fileUrl);
    xhr.send();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    showNotification("Link copied to clipboard!");
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  const renderContent = () => {
    switch (fileType) {
      case "image":
        return (
          <Box
            component="img"
            src={fileUrl}
            alt={fileName}
            sx={{
              width: "100%",
              maxWidth: 600,
              height: "auto",
              borderRadius: 2,
              my: 2,
            }}
          />
        );
      case "video":
        return (
          <Box
            component="video"
            src={fileUrl}
            controls
            sx={{
              width: "100%",
              maxWidth: 600,
              height: "auto",
              borderRadius: 2,
              my: 2,
            }}
          />
        );
      case "audio":
        return (
          <Box
            component="audio"
            src={fileUrl}
            controls
            sx={{
              width: "100%",
              maxWidth: 600,
              height: "auto",
              borderRadius: 2,
              my: 2,
            }}
          />
        );
      default:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              maxWidth: 600,
              height: 300,
              bgcolor: "grey.300",
              borderRadius: 2,
              my: 4,
            }}
          >
            <DescriptionIcon
              sx={{ fontSize: 100, color: theme.palette.primary.main }}
            />
          </Box>
        );
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        p: 4,
      }}
    >
      <Typography variant="h4" component="div">
        You purchased{" "}
        <span
          style={{ fontStyle: "italic", color: theme.palette.primary.main }}
        >
          {authorName}'s
        </span>{" "}
        content!
      </Typography>
      {renderContent()}

      <RatingComponent rating={ratings} />
      <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadClick}
        >
          Download Media
        </Button>
        <Button variant="contained" color="primary" onClick={handleCopyLink}>
          Copy Link
        </Button>
      </Box>

      <Typography
        variant="body1"
        component="div"
        sx={{ mt: 4, textAlign: "center" }}
      >
        Note: all links expire within 24 hours, please save this media if you
        want to avoid losing it.
      </Typography>
    </Container>
  );
};

export default ContentView;
