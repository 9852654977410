import React, { useState } from 'react';
import { Box, TextField, Button, Typography, IconButton, Checkbox, Container } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';
import { getTypographyStyle } from '../theme/theme';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const { login, google_login } = useAuth();
    const location = useLocation();
    const redirectUrl = new URLSearchParams(location.search).get('redirect') || '/app/upload';

    const handleUsernameInputChange = (e: any) => {
        const inputValue = e.target.value;
        setUsername(inputValue);
    };

    const handlePasswordInputChange = (e: any) => {
        const inputValue = e.target.value;
        setPassword(inputValue);
    };

    const handleRememberMeChange = (e: any) => {
        setRememberMe(e.target.checked);
    };

    const handleNewUserClick = () => {
        const searchParams = new URLSearchParams(location.search);
        navigate(`/register?${searchParams.toString()}`);
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
            <Container maxWidth="xs" sx={{ background: 'white', p: 3, borderRadius: 2, boxShadow: 3, position: 'relative' }}>
                <IconButton onClick={() => navigate('/')} sx={{ position: 'absolute', top: 8, left: 8 }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4" textAlign="center" marginBottom={3}>
                    pezmix
                </Typography>
                <Typography variant="h6" textAlign="center" marginBottom={1} sx={getTypographyStyle(500)}>
                    Login to your account
                </Typography>
                <Typography variant="body1" textAlign="center" marginBottom={2} sx={getTypographyStyle(400)}>
                    Enter your email below
                </Typography>
                <TextField
                    fullWidth
                    label="Email Address"
                    variant="outlined"
                    value={username}
                    onChange={handleUsernameInputChange}
                    margin="dense"
                    InputProps={{
                        style: getTypographyStyle(400),
                    }}
                    InputLabelProps={{
                        style: getTypographyStyle(400),
                    }}
                />
                {username && (
                    <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        type="password"
                        margin="dense"
                        onChange={handlePasswordInputChange}
                        InputProps={{
                            style: getTypographyStyle(400),
                        }}
                        InputLabelProps={{
                            style: getTypographyStyle(400),
                        }}
                    />
                )}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Checkbox sx={{ paddingLeft: 0 }} checked={rememberMe} onChange={handleRememberMeChange} />
                        <Typography variant="body2" color="textPrimary" sx={getTypographyStyle(400)}>
                            Keep me logged in
                        </Typography>
                    </Box>
                    <Typography variant="body2" color="textPrimary" sx={{ textDecoration: 'underline', cursor: 'pointer', ...getTypographyStyle(400) }}>
                        Forgot Password?
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    fullWidth
                    style={{ marginTop: '1rem', marginBottom: '1rem' }}
                    onClick={() => login(username, password, rememberMe, redirectUrl)}
                >
                    Log in
                </Button>
                {!username && (
                    <Button
                        variant="contained"
                        fullWidth
                        style={{ marginBottom: 16 }}
                        onClick={() => google_login(rememberMe, redirectUrl)}
                    >
                        Continue with Google
                    </Button>
                )}
                <Box display="flex" justifyContent="center">
                    <Typography variant="body2" color="textSecondary" sx={getTypographyStyle(400)}>
                        Don't have an account?&nbsp;
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textPrimary"
                        style={{ textDecoration: 'underline', cursor: 'pointer', ...getTypographyStyle(400) }}
                        onClick={handleNewUserClick}
                    >
                        Sign up
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default LoginPage;
