import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FilerPickerIcon from "../assets/file-picker.svg";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { getTypographyStyle } from "../theme/theme";
import UploadDetailsForm from "../components/UploadDetailsForm";
import { useAuth } from "../contexts/auth-context";

export const UploadPage = () => {
  const [file, setFile] = useState<File | null>(null);
  const { subscription } = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length) {
      setFile(droppedFiles[0]);
    }
  };
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const displayPreview = () => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        >
          {isImage && (
            <img
              src={fileURL}
              alt="Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          )}
          {isVideo && (
            <video
              src={fileURL}
              controls
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          )}
          {!isImage && !isVideo && (
            <Box sx={{ textAlign: "center" }}>
              <BrokenImageIcon sx={{ fontSize: 50 }} />
              <Typography variant="body2" noWrap>
                {file.name}
              </Typography>
            </Box>
          )}
        </Box>
      );
    }
    return (
      <Box
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleClick}
        sx={{
          border: "2px dashed #ccc",
          padding: 2,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <img
          src={FilerPickerIcon}
          alt="File Picker Icon"
          style={{ marginBottom: "16px" }}
        />
        <Typography
          variant="body1"
          textAlign="center"
          sx={getTypographyStyle(500)}
        >
          Choose a file or drag & drop it here.
        </Typography>
        <Typography
          variant="caption"
          textAlign="center"
          color="textSecondary"
          sx={getTypographyStyle(500)}
        >
          JPEG, PNG, PDF, and MP4 formats, up to{" "}
          {subscription ? "any size" : "50 MB"}.
        </Typography>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          style={{ display: "none" }}
        />
      </Box>
    );
  };
  return (
    <Container
      maxWidth="xl"
      sx={{
        background: "transparent",
        display: "flex",
        flexDirection: "column",
        alignItems: isMobile ? "center" : "flex-start",
      }}
    >
      <Typography
        variant="h4"
        sx={{ mb: 3, textAlign: isMobile ? "center" : "left" }}
      >
        Upload Product
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              height: { xs: "auto", sm: "400px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {displayPreview()}
          </Box>
        </Grid>
        <UploadDetailsForm file={file} isMobile={isMobile} setFile={setFile} />
      </Grid>
    </Container>
  );
};
export default UploadPage;
