import React, { useState } from 'react';
import { Box, Typography, Modal, Container, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/IosShare';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import DescriptionIcon from '@mui/icons-material/Description';
import { FileData } from 'pezmix-common';
import { useNotification } from '../contexts/notification-context';
import { getTypographyStyle } from '../theme/theme';
import formatString from '../utils/format-string';

interface ContentModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: (file: FileData) => void;
  file: FileData;
}

const getFileIcon = (fileType: string) => {
  switch (fileType) {
    case 'image':
      return null;
    case 'video':
      return <VideoLibraryIcon sx={{ width: 80, height: 80, borderRadius: '8px' }} />;
    case 'audio':
      return <MusicNoteIcon sx={{ width: 80, height: 80, borderRadius: '8px' }} />;
    default:
      return <DescriptionIcon sx={{ width: 80, height: 80, borderRadius: '8px' }} />;
  }
};

const ContentModal: React.FC<ContentModalProps> = ({ open, onClose, onDelete, file }) => {
  const { showNotification } = useNotification();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const handleShareClick = () => {
    if (file.link) {
      navigator.clipboard.writeText(file.link)
        .then(() => {
          showNotification('Link copied to clipboard!');
          onClose();
        })
        .catch(err => {
          showNotification(`Failed to copy link:  ${err}`);
        });
    }
  };


  const handleDownloadClick = () => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    };
    xhr.open('GET', file.url);
    xhr.send();
  };
  

  const handleDeleteClick = () => {
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = () => {
    onDelete(file);
    setConfirmDeleteOpen(false);
    onClose();
  };

  const handleCancelDelete = () => {
    setConfirmDeleteOpen(false);
  };

  const renderMediaContent = () => {
    switch (file.fileType) {
      case 'image':
        return (
          <img
            src={file.url}
            alt={file.name}
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '500px',
              borderRadius: '8px',
              objectFit: 'contain',
            }}
          />
        );
      case 'video':
        return (
          <video
            src={file.url}
            controls
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '500px',
              borderRadius: '8px',
              objectFit: 'contain',
            }}
          />
        );
      case 'audio':
        return (
          <audio
            src={file.url}
            controls
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '8px',
            }}
          />
        );
      default:
        return getFileIcon(file.fileType || 'unknown');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Container maxWidth="md" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', outline: 'none' }}>
        <Box sx={{ position: 'relative', width: '100%', bgcolor: 'background.paper', boxShadow: 24, borderRadius: 2, p: 2 }}>
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ pt: 6 }}>
            {file.url && (
              <Box sx={{ mb: 2, maxHeight: '500px', overflow: 'hidden' }}>
                {renderMediaContent()}
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box>
                <Typography variant="h6" sx={getTypographyStyle(500)}>{file.name}</Typography>
                <Typography variant="body2" color="text.secondary" sx={getTypographyStyle(400)}>
                  Created: {file.createdAt ? new Date(file.createdAt).toLocaleDateString() : 'N/A'}
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <Typography variant="subtitle1" sx={getTypographyStyle(400)}>Price to unlock: {file.price ? `$${file.price}` : 'Free'}</Typography>
                <Typography variant="body2" color="text.secondary" sx={getTypographyStyle(400)}>
                  Earnings: {formatString(file.earnings)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2, width: '100%' }}>
              <Button variant="contained" color="primary" onClick={handleShareClick} startIcon={<ShareIcon />}>
                Copy Link
              </Button>
              <Button variant="contained" color="primary" onClick={handleDownloadClick} startIcon={<DownloadIcon />}>
                Download
              </Button>
              <Button variant="contained" color="error" onClick={handleDeleteClick} startIcon={<DeleteIcon />}>
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
        <Dialog
          open={confirmDeleteOpen}
          onClose={handleCancelDelete}
        >
          <DialogTitle sx={getTypographyStyle(500)}>{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={getTypographyStyle(400)}>
              Are you sure you want to delete this file? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary" sx={getTypographyStyle(400)}>
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error" sx={getTypographyStyle(400)}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Modal>
  );
};

export default ContentModal;
