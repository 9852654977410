import ErrorPagePropsI from "./interfaces";
import { Button, Stack, Typography } from "@mui/material";
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../constants/routes";
import IMAGES from "../../constants/images";

const ErrorPage = ({ title, titleFontSize, paragraph }: ErrorPagePropsI) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(ROUTES.upload);
  };
  return (
    <Stack sx={styles.container}>
      <Stack sx={styles.imgContainer}>
        <img src={IMAGES.error} />
      </Stack>
      <Typography variant={titleFontSize} component="h1" sx={styles.title}>
        {title ? title : "Something went wrong !"}
      </Typography>
      <Typography variant="subtitle1" component="p" sx={styles.paragraph}>
        {paragraph ? paragraph : "Sorry! Please refresh the page or try later"}
      </Typography>
      <Button color="primary" variant="contained" onClick={handleNavigate}>
        Back to Main Page
      </Button>
    </Stack>
  );
};

export default ErrorPage;
