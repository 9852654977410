const COLORS = {
  mainGreen: "#136d58",
  lightGreen: "#4CAF50",
  whiteSmoke: "#F7F7F7",
  whiteSmokeLighter: "#F9F9F9",
  dandelion: "#FFD460",
  dimGray: "#707070",
  shuttleGrey: "#63676B",
  veryLightGrey: "#CCCCCC",
  midnightExpress: "#1C202E",
  white: "#FFFFFF",
  arsenic: "#393E46",
  gainsboroLight: "#DDDDDD",
  silver: "#B7B7B7",
  gainsboro: "#E5E5E5",
  lightSilver: "#C1C1C1",
  floralWhite: "#FFF8E7",
  whiteSmokeDarker: "#EFEFEF",
  redOrange: "#F44336",
  tuna: "#4A5059",
  papayaWhip: "#FFF2D0",
  darkPastelGreen: "#00C45B",
  eclipse: "#3A3A3A",
  alertColors: {
    ivory: "#F6FFED",
    reef: "#BDED99",
    aliceBlue: "#E6F7FF",
    columbiaBlue: "#A6DDFF",
    cornSilk: "#FFFBE6",
    moccasin: "#FFEDAF",
    snow: "#FFF2F0",
    mistyRose: "#FFDBD7",
    midnightExpress: "#1C202E",
  },
  darkCharcoal: "#333333",
  grape: "#41474F",
};

export default COLORS;
