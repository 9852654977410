import ENDPOINTS from "../constants/endpoints";
import axiosInstance from "../utils/axios-instance";

export const PostReport = async (reportMessage: string) => {
  const response = await axiosInstance.post(ENDPOINTS.report, {
    Message: reportMessage,
  });
  return response;
};
export const PostFeedback = async (
  feedbackMessage: string,
  serviceRating: null | number
) => {
  const response = await axiosInstance.post("/api/feedback", {
    Message: feedbackMessage,
    serviceRating: serviceRating,
  });
  return response;
};
export const CreateCheckoutSession = async (contentId: string | undefined) => {
  const response = await axiosInstance.post("/api/create-checkout-session", {
    contentId,
  });
  return response;
};

export const PostEmails = async (email: string) => {
  await axiosInstance.post("/api/v1/email-register", {
    email: email,
  });
};
