import * as React from "react";
import Card from "@mui/material/Card";
import {
  MusicNote as MusicNoteIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { ReportButton } from "../reportButton";
import { styles } from "./styles";
import { RatingComponent } from "../rating-component/rating-component";

export default function SellCard({ file, setAction, ratings }: any) {
  const { fileType, fileUrl, price, fileName, authorName } = file;
  const pricewithoutdiscount =
    Number(price) + Number(Math.random() * (9 - 1) + 1);

  return (
    <Card sx={styles.mainCard}>
      {fileUrl ? (
        <Box
          component="img"
          src={fileUrl}
          alt={fileName}
          sx={styles.LinkImgae}
        />
      ) : (
        <Box sx={styles.linkAudio}>
          {fileType === "audio" ? (
            <MusicNoteIcon sx={styles.customFont} />
          ) : (
            <DescriptionIcon sx={{ fontSize: 100 }} />
          )}
        </Box>
      )}
      <CardContent sx={styles.linkContent}>
        <Stack sx={styles.linkDetails}>
          <Typography variant="body2" color="gray">
            {authorName}
          </Typography>
          <Typography variant="h5" color="primary">
            {fileName}
          </Typography>
        </Stack>
        <RatingComponent rating={ratings} />
        <Typography variant="body1" color="text.secondary"></Typography>
        <Stack sx={{ display: "flex", flexDirection: "column" }}>
          <Stack sx={styles.priceContainer}>
            <Typography variant="h3" color="primary">
              {price}$
            </Typography>
            <Typography variant="body1" color="gray" sx={styles.discount}>
              {pricewithoutdiscount.toFixed(2)}$
            </Typography>
          </Stack>
          <Stack sx={styles.btnsContainer}>
            <Button
              variant="contained"
              onClick={() => setAction("EmailRegister")}
            >
              Buy
            </Button>
            <ReportButton />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
