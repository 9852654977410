import React from 'react';
import { Box, Typography, Container, Card, CardContent, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SvgIcon } from '@mui/material';
import FreedomSVG from '../../assets/computer.svg';
import FlexibilitySVG from '../../assets/cards.svg';
import PrivacySVG from '../../assets/security.svg';
import FastSVG from '../../assets/coins.svg';
import { ReactComponent as ArrowLogo } from '../../assets/button-arrow.svg';
import { useNavigate } from 'react-router-dom';

const SolutionContent = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const cards = [
        { title: "Stories", text: "Share links to your stories to engage your audience where they’re most active.", svg: FreedomSVG },
        { title: "Flexibility", text: "Accept one-time payments from your audience effortlessly.", svg: FlexibilitySVG },
        { title: "Privacy First", text: "100% encrypted transactions, keeping your content secure.", svg: PrivacySVG },
        { title: "Fast", text: "Get paid quickly and directly to your bank account.", svg: FastSVG }
    ];
    
    return (
        <Box id="features" sx={{ backgroundColor: theme.palette.primary.main, p: 2, textAlign: 'center', mt: 10, mb: 10 }}>
            <Container maxWidth="xl">
                <Typography variant="subtitle1" sx={{ mt: 6, mb: 4, color: 'white' }}>
                    THE SOLUTION
                </Typography>
                <Typography variant="h3" sx={{ mb: 6, color: 'white' }}>
                    Pezmix makes <span style={{ fontStyle: 'italic' }}>monetization</span> easy
                </Typography>
                <Grid container spacing={2}>
                    {cards.map((card, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    borderRadius: '24px',
                                    borderColor: theme.palette.secondary.main,
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                }}
                            >
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography variant="h6" sx={{ mt: 2 }}>{card.title}</Typography>
                                    <Typography>{card.text}</Typography>
                                </CardContent>
                                <img src={card.svg} alt={`${card.title} Icon`} style={{ width: '100%', height: 'auto', maxHeight: '200px', marginBottom: '16px' }} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Button
                    variant="contained"
                    endIcon={
                        <SvgIcon
                            component={ArrowLogo}
                            sx={{ color: theme.palette.secondary.main }}
                            inheritViewBox
                        />
                    }
                    sx={{
                        mt: 6,
                        borderRadius: '10px',
                        mb: 4,
                        backgroundColor: 'white',
                        color: theme.palette.text.primary,
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        }
                    }}
                    onClick={() => navigate('/login')}
                >
                    Start Selling for Free
                </Button>
            </Container>
        </Box>
    );
};

export default SolutionContent;
