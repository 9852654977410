import { createTheme } from "@mui/material/styles";
import COLORS from "./colors";

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.mainGreen,
    },
    secondary: {
      main: "#552D21",
    },
    background: {
      default: "#FDFBF1",
    },
    text: {
      primary: "#552D21",
    },
  },
  typography: {
    fontFamily: '"fraunces-variable", sans-serif',
    allVariants: {
      fontFamily: '"fraunces-variable", sans-serif',
      fontVariationSettings: '"WONK" 1, "SOFT" 0, "wght" 500, "opsz" 9',
    },
    h6: {
      fontFamily: '"fraunces-variable", sans-serif',
      fontVariationSettings: '"WONK" 1, "SOFT" 0, "wght" 600, "opsz" 9;',
      fontSize: 22,
      color: "#552D21",
    },
    h5: {
      fontFamily: '"fraunces-variable", sans-serif',
      fontVariationSettings: '"WONK" 1, "SOFT" 0, "wght" 600, "opsz" 9;',
    },
    button: {
      fontSize: 16,
      fontFamily: '"fraunces-variable", sans-serif',
      fontVariationSettings: '"WONK" 1, "SOFT" 0, "wght" 500, "opsz" 9;',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "#552D21",
          borderRadius: "8px",
        },
        outlined: {
          borderColor: COLORS.mainGreen,
          color: COLORS.lightGreen,
          "&:hover": {
            borderColor: "#105A46",
          },
        },
        contained: {
          backgroundColor: COLORS.mainGreen,
          color: "#FDFBF1",
          "&:hover": {
            backgroundColor: "#105A46",
          },
        },
      },
    },
  },
});

export const getTypographyStyle = (weight: number) => ({
  fontFamily: '"inter-variable", sans-serif',
  fontVariationSettings: `"slnt" 0, "wght" ${weight}`,
});

export default theme;
