import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import { Box } from "@mui/material";
import { ThumbUpAltOutlined } from "@mui/icons-material";
import { useState } from "react";
import { Rating } from "./rating-component-interface";

export const RatingComponent = (props: { rating: Rating }) => {
  const { disabledMode } = props.rating;
  const [ratings, setRatings] = useState<Rating>(props.rating);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        maxWidth: 600,
      }}
    >
      <div
        style={{
          backgroundColor: "rgb(235 231 210)",
          display: "flex",
          padding: "0.5em",
          borderRadius: "1.5rem",
        }}
      >
        {ratings.ratingAction === "like" ? (
          <ThumbUpIcon
            onClick={() => ratings.handleRating(null)}
            sx={{ cursor: "pointer" }}
          />
        ) : (
          <ThumbUpAltOutlined
            onClick={() => (disabledMode ? null : ratings.handleRating("like"))}
            sx={
              disabledMode
                ? null
                : {
                    cursor: "pointer",
                  }
            }
          />
        )}
        <p style={{ margin: 0, paddingLeft: "0.4rem" }}>{ratings.likes} </p>
        <span style={{ paddingInline: "0.5rem" }}>|</span>

        {ratings.ratingAction === "dislike" ? (
          <ThumbDownIcon
            onClick={() => ratings.handleRating(null)}
            sx={{ cursor: "pointer" }}
          />
        ) : (
          <ThumbDownAltOutlinedIcon
            onClick={() =>
              disabledMode ? null : ratings.handleRating("dislike")
            }
            sx={
              disabledMode
                ? null
                : {
                    cursor: "pointer",
                  }
            }
          />
        )}

        <p style={{ margin: 0, paddingLeft: "0.4rem" }}>{ratings.dislikes}</p>
        <span style={{ paddingInline: "0.5rem" }}>|</span>
        <p style={{ margin: 0, color: ratings.percentage.color }}>
          {ratings.percentage.value}%
        </p>
      </div>
    </Box>
  );
};
