export const styles = {
  EmailRegisterContainer: {
    display: "flex",
    flexDirection: {
      xs: "column%",
      sm: "row",
    },
    alignItems: "center",
    justifyContent: "center",
    padding: "1.5rem",
    height: "100vh",
  },
  LinkImgae: {
    width: {
      xs: "100%",
      sm: "70%",
    },
    height: "100%",
  },
  StackConatainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  MediaSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "1rem",
    width: {
      xs: "100%",
      sm: "70%",
    },
  },
  EmailForm: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: { xs: 4, sm: 2 },
    width: {
      xs: "100%",
      sm: "70%",
    },
  },
  linkAudio: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70%",
    height: 300,
    bgcolor: "white",
  },
  customFont: { fontSize: 100 },
};
