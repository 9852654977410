import {
  GoogleAuthProvider,
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from "firebase/auth";
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { auth } from "../config/firebase-config";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNotification } from "./notification-context";
import axiosInstance from "../utils/axios-instance";
import {
 
  getFirestore,
} from "firebase/firestore";

interface AuthContextType {
  isLoggedIn: boolean;
  isAuthReady: boolean;
  username: string;
  walletId: string | null;
  customerId: string | null;
  subscription: boolean | null;
  login: (
    email: string,
    password: string,
    rememberMe: boolean,
    redirectUrl?: string
  ) => void;
  google_login: (rememberMe: boolean, redirectUrl?: string) => void;
  register: (
    username: string,
    email: string,
    password: string,
    redirectUrl?: string,
    accountType?: string
  ) => void;
  logout: () => void;
  fetchWallet: (
    userId: string,
    createStripeAccount?: boolean
  ) => Promise<{ account: string | null; error: string | null }>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [walletId, setWalletId] = useState<string | null>(null);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [subscription, setSubscription] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUsername(user.displayName ?? "Anonymous");
        fetchWallet(user.uid);
      } else {
        setIsLoggedIn(false);
        setUsername("");
        setWalletId(null);
      }
      setIsAuthReady(true);
    });

    return () => {
      unsubscribe();
      setIsAuthReady(false);
    };
  }, []);

  const setPersistenceType = async (rememberMe: boolean) => {
    const persistence = rememberMe
      ? browserLocalPersistence
      : browserSessionPersistence;
    await setPersistence(auth, persistence).catch((error) => {
      showNotification("Could not set persistent login...");
    });
  };

  const google_login = (rememberMe: boolean, redirectUrl?: string) => {
    setIsLoading(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        setIsLoggedIn(true);
        setUsername(user.displayName ?? "Anonymous");
        fetchWallet(user.uid);
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate("/app/upload");
        }
      })
      .catch((error) => {
        showNotification(
          `Google login failed: ${error.code}, ${error.message}`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const login = async (
    email: string,
    password: string,
    rememberMe: boolean,
    redirectUrl?: string
  ) => {
    setIsLoading(true);
    await setPersistenceType(rememberMe);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setIsLoggedIn(true);
        setUsername(user.displayName ?? "Unknown");
        fetchWallet(user.uid);
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate("/app/upload");
        }
      })
      .catch((error) => {
        showNotification(`Login failed: ${error.code}, ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const register = async (
    username: string,
    email: string,
    password: string,
    redirectUrl?: string,
    accountType?: string
  ) => {
    setIsLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // @ts-ignore
      window.fpr("referral", { email: email });

      const user = userCredential.user;
   
      await updateProfile(user, {
        displayName: username,
      });

      setIsLoggedIn(true);
      setUsername(username);
      fetchWallet(user.uid);
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate("/app/upload");
      }
    } catch (error) {
      showNotification(`Error in registration ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => {
    setIsLoading(true);
    signOut(auth)
      .then(() => {
        setIsLoggedIn(false);
        setUsername("");
        setWalletId(null);
      })
      .catch((error) => {
        showNotification(`Logout failed ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchWallet = async (
    userId: string,
    createStripeAccount: boolean = false
  ) => {
    try {
      const response = await axiosInstance.post("/api/account", {
        userId,
        createStripeAccount,
      });

      const account = response.data.account;
      const customerId = response.data.customer;
      const subscription = response.data.subscription;

      setWalletId(account);
      setCustomerId(customerId);
      setSubscription(subscription);

      return { account, error: null };
    } catch (error) {
      return { account: null, error: (error as Error).message };
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isAuthReady,
        username,
        walletId,
        customerId,
        subscription,
        login,
        google_login,
        register,
        logout,
        fetchWallet,
      }}
    >
      {children}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AuthContext.Provider>
  );
};
