import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Typography, useMediaQuery, List, ListItem, ListItemText, Paper, Backdrop, CircularProgress } from '@mui/material';
import { useAuth } from '../contexts/auth-context';
import theme, { getTypographyStyle } from '../theme/theme';
import { auth } from '../config/firebase-config';
import axiosInstance from '../utils/axios-instance';

const AccountPage = () => {
  const { walletId, fetchWallet } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [pendingBalance, setPendingBalance] = useState<number | null>(null);
  const [transactions, setTransactions] = useState<any[]>([]);

  const setupWallet = async () => {
    setLoading(true);
    setError(null);

    try {
      if (auth.currentUser) {
        const response = await fetchWallet(auth.currentUser.uid, true);
        const { account, error } = response;

        if (account) {
          const linkResponse = await axiosInstance.post('/api/account_manage', {
            account,
          });

          const { url, error: linkError } = linkResponse.data;

          if (url) {
            window.location.href = url;
          } else if (linkError) {
            setError('An error occurred while creating the account link');
          }
        } else if (error) {
          setError(error);
        }
      } else {
        setError('User is not authenticated');
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const manageWallet = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.post('/api/account_manage', {
        account: walletId,
      });

      const { url, error } = response.data;

      if (url) {
        window.location.href = url;
      } else if (error) {
        setError('An error occurred while creating the login link');
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const fetchPendingBalance = async (accountId: string) => {
    try {
      const response = await axiosInstance.post('/api/account_pending_balance', { account: accountId });
      setPendingBalance(response.data.pendingBalance);
    } catch (error) {
      setError((error as Error).message);
    }
  };

  const fetchLastTransactions = async (accountId: string) => {
    try {
      const response = await axiosInstance.post('/api/account_last_transactions', { account: accountId });
      setTransactions(response.data.transactions);
    } catch (error) {
      setError((error as Error).message);
    }
  };

  useEffect(() => {
    if (walletId) {
      fetchPendingBalance(walletId);
      fetchLastTransactions(walletId);
    }
  }, [walletId]);

  useEffect(() => {
    const loadWallet = async () => {
      setLoading(true);
      setError(null);

      try {
        if (auth.currentUser) {
          const response = await fetchWallet(auth.currentUser.uid);
          const { account, error } = response;

          if (account) {
          } else if (error) {
            setError(error);
          }
        } else {
          setError('User is not authenticated');
        }
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    loadWallet();
  }, [fetchWallet]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        background: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: isMobile ? 'center' : 'flex-start',
      }}
    >
      <Backdrop open={loading} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          mb: 3,
        }}
      >
        <Typography
          variant="h4"
          sx={{ textAlign: isMobile ? 'center' : 'left' }}
        >
          Wallet
        </Typography>
        {walletId ? (
          <Button
            variant="contained"
            color="primary"
            onClick={manageWallet}
            disabled={loading}
            className="wallet-setup"
          >
            Manage your wallet
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={setupWallet}
            disabled={loading}
            className="wallet-setup"
          >
            Set your wallet up
          </Button>
        )}
      </Box>
      {walletId && (
        <>
          <Box
            component={Paper}
            sx={{
              width: '100%',
              background: 'white',
              p: 2,
              mb: 3,
              borderRadius: 2,
              boxShadow: 1,
            }}
          >
            <Typography variant="body1" color='black' sx={{ ...getTypographyStyle(500), mb: '1rem' }}>Pending Balance</Typography>
            <Typography variant="h3" color='black' sx={getTypographyStyle(500)}>{pendingBalance !== null ? `$${pendingBalance}` : 'Loading...'}</Typography>
          </Box>
          <Box
            component={Paper}
            sx={{
              width: '100%',
              background: 'white',
              p: 2,
              mb: 3,
              borderRadius: 2,
              boxShadow: 1,
            }}
          >
            <Typography variant="body1" color='black' sx={{ mb: '1rem', ...getTypographyStyle(500) }}>
              Last Transactions
            </Typography>
            <Box
              sx={{
                height: 400,
                overflowY: 'auto',
              }}
            >
              {transactions.length > 0 ? (
                <List>
                  {transactions.map((transaction) => (
                    <ListItem key={transaction.id} divider>
                      <ListItemText
                        primaryTypographyProps={{
                          sx: { color: 'black', ...getTypographyStyle(500) }
                        }}
                        secondaryTypographyProps={{
                          sx: { color: theme.palette.grey[500], ...getTypographyStyle(500) }
                        }}
                        primary={new Date(transaction.created * 1000).toLocaleString()}
                        secondary={`Amount: $${(transaction.amount / 100).toFixed(2)}, Status: ${transaction.status}`}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2" color={theme.palette.grey[500]} sx={getTypographyStyle(500)}>
                  No transactions found.
                </Typography>
              )}
            </Box>
          </Box>
        </>
      )}
      {error && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default AccountPage;
