const formatString = (amount?: number) => {
    if(!amount) return `$0.00`;
    if (amount >= 1e6) {
      return `$${(amount / 1e6).toFixed(1)}M`;
    } else if (amount >= 1e3) {
      return `$${(amount / 1e3).toFixed(1)}k`;
    } else {
      return `$${amount.toFixed(2)}`;
    }
  };

export default formatString;