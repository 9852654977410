import { db } from '../config/firebase-config';
import { doc, setDoc } from 'firebase/firestore';
import { FileData } from 'pezmix-common';

const generateContentLink = async (file: FileData, access: string, price: string, uniqueId: string) => {
    const link = `${window.location.origin}/content/${uniqueId}`;
  
    const contentData = {
      fileName: file.name,
      authorUid: file.authorUid,
      authorName: file.authorName,
      fileUrl: file.path,
      accessType: access,
      price: access === 'private' ? price : 'FREE',
      createdAt: new Date(),
      link
    };
  
    const docRef = doc(db, 'shared_content', uniqueId);
    await setDoc(docRef, contentData);
  
    return link;
  };
  
  export { generateContentLink };
  