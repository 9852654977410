import React, { useState } from 'react';
import { Drawer, Box, List, ListItemIcon, ListItemText, Typography, Toolbar, IconButton, AppBar, ListItemButton, Button, Avatar, Menu, MenuItem, Container } from '@mui/material';
import { useAuth } from '../contexts/auth-context';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import HomeIcon from '../assets/home-icon.svg';
import LinksIcon from '../assets/links-icon.svg';
import WalletIcon from '../assets/wallet-icon.svg';
import SettingsIcon from '../assets/settings-icon.svg';
import { auth } from '../config/firebase-config';
import { getTypographyStyle } from '../theme/theme';

const drawerWidth = 240;

const NavigationMenu = () => {
  const { logout, subscription } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [displayName] = useState(auth.currentUser?.displayName || 'User');
  const [profileImage] = useState(auth.currentUser?.photoURL || '');

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleReferralLink = () => {
    window.location.href = 'https://pezmix.firstpromoter.com/login';
  };

  const drawer = (
    <Box sx={{ width: drawerWidth, flexShrink: 0 }}>
      <Toolbar>
        <Typography variant="h6" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          pezmix
          {subscription && (
            <Box
              component="span"
              sx={{
                ml: 1,
                fontSize: '0.5rem',
                padding: '2px 4px',
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: '4px',
                color: theme.palette.primary.main,
                textTransform: 'uppercase',
                fontWeight: 'bold',
              }}
            >
              Premium
            </Box>
          )}
        </Typography>
      </Toolbar>
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <ListItemButton component={NavLink} to="/app/upload" className="nav-upload">
            <ListItemIcon>
              <img src={HomeIcon} alt="Home" />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
          <ListItemButton component={NavLink} to="/app/content" className="nav-content">
            <ListItemIcon>
              <img src={LinksIcon} alt="Links" />
            </ListItemIcon>
            <ListItemText primary="Links" />
          </ListItemButton>
          <ListItemButton component={NavLink} to="/app/wallet" className="nav-wallet">
            <ListItemIcon>
              <img src={WalletIcon} alt="Wallet" />
            </ListItemIcon>
            <ListItemText primary="Wallet" />
          </ListItemButton>
          <ListItemButton component={NavLink} to="/app/settings" className="nav-settings">
            <ListItemIcon>
              <img src={SettingsIcon} alt="Settings" />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </List>
        {!subscription && (
          <Box sx={{ p: 2 }}>
            <Button variant="contained" color="primary" fullWidth onClick={() => navigate(`/create-sub-checkout-session?priceId=${process.env.REACT_APP_PRICE_ID_50!}`)}>
              Get Premium
            </Button>
          </Box>
        )}
        <Box sx={{ p: 2 }}>
          <Button variant="contained" color="secondary" fullWidth onClick={handleReferralLink}>
            Earn 50% Commission
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <AppBar
        position="fixed"
        color="default"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          zIndex: theme.zIndex.drawer - 1,
          width: `calc(100% - ${isMobile ? 0 : drawerWidth}px)`,
          ml: isMobile ? 0 : `${drawerWidth}px`,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={profileImage} />
              <Typography variant="body1" noWrap sx={{ ml: 2, ...getTypographyStyle(500), fontSize: { xs: '0.7rem', md: '1rem' }, }}>
                {displayName.length > 15 ? `${displayName.slice(0, 12)}...` : displayName}
              </Typography>
              <IconButton color="inherit" onClick={handleMenuOpen}>
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => { handleMenuClose(); navigate('settings'); }}
                  sx={getTypographyStyle(500)}
                >
                  Settings
                </MenuItem>
                <MenuItem
                  onClick={() => { handleMenuClose(); logout(); }}
                  sx={getTypographyStyle(500)}
                >
                  Logout
                </MenuItem>
              </Menu>
              <Button variant="outlined" color="primary" sx={{
                ml: 2,
                padding: { xs: '4px 8px', md: '6px 16px' },
                fontSize: { xs: '0.7rem', md: '1rem' },
                minWidth: { xs: '90px', md: 'auto' }
              }} component={NavLink} to="/app/upload">
                Generate Link
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        {isMobile ? (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, overflow: 'hidden' },

            }}
          >
            {drawer}
          </Drawer>
        ) : (
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                overflow: 'hidden'
              },
            }}
            anchor="left"
          >
            {drawer}
          </Drawer>
        )}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: 15,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default NavigationMenu;
